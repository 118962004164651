import React from 'react';
import { observable, action, makeObservable } from 'mobx';
import { inject, observer } from 'mobx-react';
import parse from 'html-react-parser';

import PagesStore from '../../stores/Pages';
import LocalizedLink from '../LocalizedLink';

import './styles/index.scss';
import ErrorBanner from '../404';

interface Props {
  pageSlug: string;
  pages?: PagesStore;
}

@inject('pages')
@observer
export default class Content extends React.Component<Props> {
  @observable content = '';
  @observable currentPageSlug = '';
  @observable error = false;

  constructor(props: Props) {
    super(props);
    makeObservable(this);
  }

  componentDidMount() {
    this.updatePage();
  }

  componentDidUpdate() {
    const { pageSlug } = this.props;

    if (pageSlug !== this.currentPageSlug) {
      this.updatePage();
    }
  }

  async updatePage() {
    const { pageSlug, pages } = this.props;

    this.setCurrentPageSlug(pageSlug);

    try {
      await pages.loadList();

      // Check if page exists
      const page = pages.list.find((p) => p.slug === pageSlug);
      const content = await pages.loadContent(page.id);
      this.setContent(content);
      this.setError(false);
    } catch (err) {
      this.setError(true);
    }
  }

  @action setCurrentPageSlug(slug: string) {
    this.currentPageSlug = slug;
  }

  @action setContent(content: string) {
    this.content = content;
  }

  @action setError(status: boolean) {
    this.error = status;
  }

  render() {
    const { pages } = this.props;

    if (this.error) {
      return <ErrorBanner />;
    }

    return (
      <div className="component-content">
        <div className="old-container">
          <div className="section">
            <div className="flex">
              <div className="sidebar">
                <ul className="sidebar-list">
                  {pages.list.map((page) => (
                    <li key={page.id}>
                      <LocalizedLink
                        to={`/${page.slug}`}
                        className={
                          this.currentPageSlug === page.slug ? 'active' : ''
                        }
                      >
                        {page.title}
                      </LocalizedLink>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="right">{parse(this.content)}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
